
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LogisticsItem',
  components: {
    ...importComponents(
    ),
  },
  props: {
    logisticsItemData: Object,
    orderStatus: {
      type: String,
    },
  },
  setup() {
    return {
    };
  },
});
