<template>
<div class="logistics-item">
    <div class="img-wrapper">
        <img src="@/assets/icons/logistics/pending.svg" alt=""
        v-if="orderStatus === '2'">
        <img src="@/assets/icons/logistics/completed.png" alt="" v-else>
    </div>
    <div class="logistics-message" :class="{'active': orderStatus === '2'}">
        <span class='message-head'>{{logisticsItemData.logisticsStation}}</span>
        <span class="message-time">{{logisticsItemData.logisticsTime}}</span>
    </div>
</div>
</template>

<script lang='ts'>
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LogisticsItem',
  components: {
    ...importComponents(
    ),
  },
  props: {
    logisticsItemData: Object,
    orderStatus: {
      type: String,
    },
  },
  setup() {
    return {
    };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    .logistics-item{
        display: flex;
        align-items: center;
        margin-bottom: 41px;
        .img-wrapper{
            @include flex-center();
            margin-right: 10px;
            border-radius: 50%;
            position: relative;
            width: 38px;
            height: 38px;
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 99%;
                left: 50%;
                transform: translate(-50%);
                height: 43px;
                width: 1px;
                background: #969696;
        }
            img{
            width: 100%;
            }
        }
        .logistics-message{
            display: flex;
            flex-direction: column;
            .message-head{
                font-size: 18px;
                font-weight: 400;
                color: #969696;
                line-height: 18px;
                margin-bottom: 4px;
            }
            .message-time{
                font-size: 12px;
                font-weight: 500;
                color: #969696;
                line-height: 16px;
            }
            &.active{
                .message-head{
                    color: $theme-color;
                }
                .message-time{
                    color: $font-color-weak;
                }
            }
        }
    }
</style>
