<template>
    <Load :loading="loading" v-if="loading"/>
    <region-wrapper v-else>
        <item-header :title="`${t('logistics.number')}${trackingNumber?trackingNumber:'error'}`"/>
        <div class="logistics-item-wrapper">
            <LogisticsItem v-for="(item, index) in logisticsList" :key="item.logisticsTime"
            :logistics-item-data="item" :orderStatus="index === 0?orderStatus:'0'"/>
        </div>
    </region-wrapper>
</template>

<script lang='ts'>
import importComponents from '@/utils/import-components';
import {
  defineComponent, onBeforeMount, reactive, ref,
} from 'vue';
import LogisticsItem from '@/components/order/LogisticsItem.vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import Cookies from 'js-cookie';
import { getOrderDetailAddress } from '@/services/user';
import { getOrderLogistics } from '@/services/order';
import { useRoute } from 'vue-router';
import type { OrderLogistics } from '@/services/interface/response';
import Load from '@/components/common/Load.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Logistics',
  components: {
    LogisticsItem,
    RegionWrapper,
    ItemHeader,
    Load,
    ...importComponents(
    ),
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(true);
    const route = useRoute();
    const orderNumber = route.fullPath.split('?')[1];
    const logisticsList: OrderLogistics[] = reactive([]);
    const orderStatus = ref('0');
    const trackingNumber = ref('0');
    onBeforeMount(async () => {
      if (Cookies.get('token')) {
        try {
          const addressRes = await getOrderDetailAddress({ orderNumber });
          const logisticsRes = await getOrderLogistics({ orderNumber });
          if (addressRes.success && logisticsRes.success) {
            orderStatus.value = addressRes.data.orderStatus;
            Object.assign(logisticsList, logisticsRes.data.tracks.reverse());
            trackingNumber.value = logisticsRes.data.trackingNumber;
            loading.value = false;
          }
        } catch (e) {
          console.error(e);
        }
      }
    });
    return {
      logisticsList,
      orderNumber,
      orderStatus,
      trackingNumber,
      loading,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import '@/assets/styles/variables.scss';

    :deep(.region-wrapper) {
        .region-content{
            padding-bottom: 102px;
        }
    }
    :deep(header) {
        border-bottom: 1px solid #E1E1E1;
        margin-bottom: 84px;

            h2 {
                text-align: left;
                margin-top: 41px;
                margin-bottom: 33px;
                padding: 0 20px;
                font-size: 25px;
                font-weight: 600;
                color: $font-color;
                line-height: 30px;
            }
    }
    .logistics-item-wrapper{
        :deep(.logistics-item){
                &:last-child{
                    margin-bottom: 195px;
                .img-wrapper{
                    &::after{
                        content: '';
                        display: block;
                        height: 0;
                        width: 0;
                    }
                }
            }
        }
    }
</style>
