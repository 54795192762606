
import importComponents from '@/utils/import-components';
import {
  defineComponent, onBeforeMount, reactive, ref,
} from 'vue';
import LogisticsItem from '@/components/order/LogisticsItem.vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import Cookies from 'js-cookie';
import { getOrderDetailAddress } from '@/services/user';
import { getOrderLogistics } from '@/services/order';
import { useRoute } from 'vue-router';
import type { OrderLogistics } from '@/services/interface/response';
import Load from '@/components/common/Load.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Logistics',
  components: {
    LogisticsItem,
    RegionWrapper,
    ItemHeader,
    Load,
    ...importComponents(
    ),
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(true);
    const route = useRoute();
    const orderNumber = route.fullPath.split('?')[1];
    const logisticsList: OrderLogistics[] = reactive([]);
    const orderStatus = ref('0');
    const trackingNumber = ref('0');
    onBeforeMount(async () => {
      if (Cookies.get('token')) {
        try {
          const addressRes = await getOrderDetailAddress({ orderNumber });
          const logisticsRes = await getOrderLogistics({ orderNumber });
          if (addressRes.success && logisticsRes.success) {
            orderStatus.value = addressRes.data.orderStatus;
            Object.assign(logisticsList, logisticsRes.data.tracks.reverse());
            trackingNumber.value = logisticsRes.data.trackingNumber;
            loading.value = false;
          }
        } catch (e) {
          console.error(e);
        }
      }
    });
    return {
      logisticsList,
      orderNumber,
      orderStatus,
      trackingNumber,
      loading,
      t,
    };
  },
});
